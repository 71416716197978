<template>
	<div>
		<a-config-provider :locale="zh_CN">
			<a-card>
				<a-row style="margin-bottom: 20px;">
					<a-col :span="8">
						<a-button type="primary" @click="createissue" v-if="isGranted('problem_management_create')">
							<a-icon type="plus" />
							{{ l('Create') }}
						</a-button>

						<a-button type="danger" @click="batchdelete" v-if="isGranted('problem_management_delete')">
							<a-icon type="delete" />
							{{ l('Delete') }}
						</a-button>

						<a-button @click="Export" v-if="isGranted('problem_management_export')">
							<a-icon type="export" />
							{{ l('Export') }}
						</a-button>
					</a-col>
					<a-col :span="16" style="text-align: right;">

						<a-range-picker @change="DiscoverytimeChange"
							:placeholder="[l('SubmissionStartTime'), l('SubmissionEndTime')]" v-model="timePeriod"
							style=" width: 250px;text-align: center;margin-right:10px;" />

						<a-select allowClear :placeholder="l('issueclassify')" style="width: 120px;margin-right: 10px;"
							@change="statusChange">
							<a-select-option v-for="item in typeCodeList" :key="item.itemDetailCode">
								{{ item.itemDetailName }}
							</a-select-option>
						</a-select>
						<a-tree-select style="width: 200px;margin-right: 10px;" :tree-data="tree" :replace-fields="treeRep"
							:dropdown-style="{ maxHeight: '400px', overflow: 'auto', width: '300px' }"
							:placeholder="l('issuetype')" @change="treeRepChange" allow-clear tree-default-expand-all>
						</a-tree-select>
						<a-select mode="multiple" :filterOption="filterOption"
							style="width: 176px;text-align: left;margin-right: 10px;" :maxTagCount="1"
							:placeholder="l('issueprogress.Progress')" @change="AuditTaskStateChange" allow-clear>
							<a-select-option v-for="item in AuditTaskStateList" :key="item.progress"
								:title="item.itemDetailName">
								{{ item.name }}
							</a-select-option>
						</a-select>
						<a-input-search :placeholder="l('Please_enter_the_problem_description_to_search')"
							v-model.trim="filterText" enterButton style="width: 200px;" @search="filteronchange">
						</a-input-search>
					</a-col>
					<a-col :span="24" style="margin-top: 10px;text-align: right;">
						<a-checkbox @change="onChange" style="margin-right: 10px;">
							{{ l('Showonlythefollowings') }}
						</a-checkbox>

						<a-tree-select v-model="orgId" v-if="show1" style="width: 200px;margin-right: 10px;"
							:tree-data="orgtreeDataList" :replace-fields="orgtreeReplaceFields"
							:dropdown-style="{ maxHeight: '400px', overflow: 'auto', width: '300px' }"
							:placeholder="l('please_select_organization_structure')" :dropdownMatchSelectWidth="false"
							allow-clear tree-default-expand-all @change="orgChange">
						</a-tree-select>

						<a-select showSearch v-if="show1" :filterOption="filterOption"
							style="width: 150px;text-align: left;margin-right: 10px;" dropdownClassName="drop-down-Style"
							:dropdownMatchSelectWidth="false" :placeholder="l('Please_select_the_person_responsible')"
							allow-clear @change="LPAUserChange">
							<a-select-option v-for="item in AllLPAUser" :key="item.id" :title="item.realName">
								{{ item.realName }}
							</a-select-option>
						</a-select>

						<a-tree-select style="width: 200px;margin-right:10px;" :tree-data="treeData"
							:replace-fields="treeRepData"
							:dropdown-style="{ maxHeight: '400px', overflow: 'auto', width: '300px' }"
							:placeholder="l('220')" @change="treeRepChange1" allow-clear v-if="show1" v-model="auditPointId"
							tree-default-expand-all>
						</a-tree-select>



						<a-range-picker @change="timeChange" :placeholder="[l('audit.planstart'), l('audit.planend')]"
							v-if="show1" style=" width: 250px;text-align: center;margin-right:10px;" />
						<a @click="showData" v-if="!show1">{{ l('Displays_advanced_filtering') }}
							<a-icon type="down" v-if="!show1" />
						</a>
						<a @click="showData" v-if="show1">{{ l('Hide_advanced_filtering') }}
							<a-icon type="up" v-if="show1" />
						</a>
					</a-col>
					<!-- <a-col :span="24" style="margin-top: 10px;text-align: right;" v-if="!show">
						<a @click="showData">{{l('Displays_advanced_filtering')}}<a-icon type="down" /></a>
					</a-col>
					<a-col :span="24" style="margin-top: 10px;text-align: right;" v-if="show">
						<a @click="showData">{{l('Hide_advanced_filtering')}}<a-icon type="up" /></a>
					</a-col> -->
				</a-row>
				<a-spin :spinning="isLoading" style="top: -10px;">
					<a-table :columns="columns" :data-source="tableData" :customRow="customRow"
						:row-selection="{ selectedRowKeys: selectedRowKey, onSelect: onSelect, onSelectAll: SelectAll, columnWidth: '50px' }"
						:rowKey="(record) => record.id" :scroll="{ x: 1000, y: 600 }" :pagination="false" size="small">
						<span slot="description" slot-scope="text,record" class="portlet">
							<a @click.stop="concern(record)" style="margin-top: 2px;margin-right: 3px;">
								<a-icon type="star" style="color: #f4ca3a;font-size: 18px;"
									:theme="record.issueSubscribeId ? 'filled' : 'outlined'"
									:title="record.issueSubscribeId ? '关注' : '未关注'" />
							</a>

							<a-icon type="exception" v-if="record.sourceName == sourceName" :title="sourceName"
								style="margin-top: 4px;margin-right: 2px" />
							<div class="images" v-vuer="{ title: false }" style="width: 75px;overflow: hidden;" id="index"
								v-if="record.discoveryPicture.length > 2">
								<a v-if="item != image" style="margin-left: 5px;" v-for="item in record.discoveryPicture"
									:key="record.realPath + '/' + item">
									<!--                <a-icon type="picture"/>-->
									<img :src="record.realPath + '/' + item"
										style="height: 15px;width: 20px;margin-bottom: 2px" />
								</a>
							</div>
							<div class="images" v-vuer="{ title: false }" style="margin-right: 5px;" id="index"
								v-if="record.discoveryPicture.length > 0 && record.discoveryPicture.length < 3">
								<a v-if="item != image" style="margin-left: 5px;" v-for="item in record.discoveryPicture"
									:key="record.realPath + '/' + item">
									<!--                <a-icon type="picture"/>-->
									<img :src="record.realPath + '/' + item"
										style="height: 15px;width: 20px;margin-bottom: 2px" />
								</a>
							</div>
							<span v-if="record.discoveryPicture.length > 2" style="margin-right: 5px;">...</span>
							<a @click="view(record)" v-if="record.sourceName == sourceName" class="view-data">
								{{ record.description }}</a>
							<a @click="view(record)" v-if="record.sourceName != sourceName"
								class="view-data">{{ record.description }}</a>
						</span>
						<span slot="issueProgress" slot-scope="text,record" style="text-align: center">
							<div>{{ record.issueProgress }}%</div>
						</span>
						<span slot="action" slot-scope="text,record">
							<a @click.stop="edit(record)" v-if="isGranted('problem_management_edit')">
								<a-icon type="edit" />{{ l('Edit') }}
							</a>
							<font
								v-if="isGranted('problem_management_edit') && isGranted('problem_management_close') && record.issueProgress != 100">
								|
							</font>
							<a @click.stop="CloseTheIssue(record)"
								v-if="record.issueProgress != 100 && isGranted('problem_management_close')">
								<a-icon type="safety-certificate" />{{ l('Close_the_issue') }}
							</a>
							<!-- <a-popconfirm :title="l('Whether_to_confirm_the_closure_of_the_issue')" :ok-text="l('Ok')" :cancel-text="l('Cancel')" @confirm="() => CloseTheIssue(record)" v-if="record.issueProgress!=100&&isGranted('problem_management_close')">
							    <a><a-icon type="safety-certificate" />{{l('Close_the_issue')}}</a>
							</a-popconfirm> -->
							<font
								v-if="isGranted('problem_management_close') && record.issueProgress != 100 || isGranted('problem_management_Referral') || isGranted('problem_management_delete')">
								|
							</font>
							<a-dropdown>
								<a-menu slot="overlay" @click="handleMenuClick">
									<!-- <a-menu-item key="1" @click="handleMenu(record)"
										v-if="record.issueProgress!=100&&isGranted('problem_management_close')">
										<a-icon type="safety-certificate" />
										{{l('Close_the_issue')}}
									</a-menu-item> -->
									<a-menu-item key="1" @click="handleMenu(record)"
										v-if="isGranted('problem_management_Referral')">
										<a-icon type="rollback" />
										{{ l('Referral') }}
									</a-menu-item>
									<a-menu-item key="2" style="color: red;" @click="handleMenu(record)"
										v-if="isGranted('problem_management_delete')">
										<a-icon type="delete" />
										{{ l('Delete') }}
									</a-menu-item>
								</a-menu>
								<a v-if="isGranted('problem_management_Referral') || isGranted('problem_management_delete')">
									<a-icon type="down" />{{ l('more') }}...
								</a>
							</a-dropdown>
							<!-- <font v-if="isGranted('audit_task_delete')&&isGranted('audit_task_edit')"> | </font>
						<a @click.stop="deletetable(record)" v-if="isGranted('audit_task_delete')">
							<a-icon type="delete"/>{{l('Delete')}}
						</a> -->
						</span>
					</a-table>
				</a-spin>
				<a-pagination class="pagination" :total="totalItems" showSizeChanger showQuickJumper
					:defaultPageSize="request.maxResultCount" :pageSizeOptions="pageSizeOptions"
					:show-total="total => `共有 ${totalItems} 条`" @change="onChangePage" @showSizeChange="showSizeChange" />
			</a-card>
		</a-config-provider>
	</div>
</template>

<script>
import {
	AppComponentBase
} from "@/shared/component-base";
import ModalComponentBase from '../../../shared/component-base/modal-component-base';
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import {
	IssueServiceProxy,
	IssueProgressServiceProxy,
	IssueTypeServiceProxy,
	AuditPointServiceProxy,
	LayoutOrgServiceProxy,
	AuditTaskServiceProxy
} from '../../../shared/service-proxies';
import {
	ModalHelper
} from '@/shared/helpers';
import moment from "moment";
import {
	Dic
} from "../../../shared/utils";
import transfer from "./transfer/transfer.vue"
import view from "./view/view.vue"
import CreatErrorCode from "./UpdataIssue/updataIssue.vue"
import createissue from "./create-issue/create-issue.vue"
import closeissue from "./close-issue/close-issue.vue"
import {
	AppConsts
} from "../../../abpPro/AppConsts";
import 'viewerjs/dist/viewer.css'
import Vuer from 'v-viewer'
import Vue from 'vue'
import { fileDownloadService } from "../../../shared/utils";
Vue.use(Vuer, {
	name: 'vuer'
})

let _this;
export default {
	name: "problem-management",
	mixins: [ModalComponentBase],
	components: {
		transfer,
		view,
		CreatErrorCode,
		closeissue
	},
	created() {
		_this = this;
		this._fileDownloadService = fileDownloadService;
		this.IssueServiceProxy = new IssueServiceProxy(this.$apiUrl, this.$api);
		this.IssueProgressServiceProxy = new IssueProgressServiceProxy(this.$apiUrl, this.$api);
		this.IssueTypeServiceProxy = new IssueTypeServiceProxy(this.$apiUrl, this.$api);
		this.AuditPointServiceProxy = new AuditPointServiceProxy(this.$apiUrl, this.$api);
		this._LayoutOrgServiceProxy = new LayoutOrgServiceProxy(this.$apiUrl, this.$api);
		this.AuditTaskServiceProxy = new AuditTaskServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		this.fullData(); // 模态框必须,填充数据到data字段
		this.typeCodeList = await Dic.getInstance().getDicAsync("SourceType");
		if (this.startTime) {
			this.timePeriod = [moment(this.startTime), moment(this.endTime)];
			this.Discoverystartdate = moment(this.startTime);
			this.Discoveryenddate = moment(this.endTime);
		}
		if (this.auditPointId) {
			this.treeRepDataClass = Number(this.auditPointId);
		}
		this.getData();
		this.getissueProgress();
		this.getAllLPAUser();
		this.getTreeData2();
		this.getTreeData();
		this.getorgTreeData();
	},
	data() {
		return {
			zh_CN,
			isEdit: false,
			isLook: false,

			filterText: undefined,
			totalItems: 0, //总数
			// 当前页码
			pageNumber: 1,
			// 共多少页
			totalPages: 1,
			// 条数显示范围
			pagerange: [1, 1],
			// 显示条数
			pageSizeOptions: ["10", "20", "50", "100", "500"],
			request: {
				maxResultCount: 20,
				skipCount: 0
			},

			tableData: [],
			columns: [{
				title: this.l('Submissiontime'),
				width: 150,
				dataIndex: 'discoveryTime',
				align: 'center',
				ellipsis: true
			},
			// {title: this.l('issuetype'), width: 100, dataIndex: 'sourceName', align: 'center',},
			{
				title: this.l('issuetype'),
				width: 200,
				dataIndex: 'issueTypeListName',
				align: 'center',
				ellipsis: true,
			},
			{
				title: this.l('issue.description'),
				width: 250,
				dataIndex: 'description',
				align: 'left',
				ellipsis: true,
				scopedSlots: {
					customRender: "description"
				}
			},
			{
				title: this.l('issueprogress.Progress'),
				width: 120,
				dataIndex: 'issueProgressName',
				align: 'center',
				ellipsis: true
			},
			{
				title: this.l('layoutuser.isresponsible'),
				width: 100,
				dataIndex: 'respName',
				align: 'center',
				ellipsis: true,
			},
			{
				title: this.l('Responsible_Department'),
				width: 100,
				dataIndex: 'respDept',
				align: 'center',
				ellipsis: true,
			},
			{
				title: this.l('Discoverer'),
				width: 100,
				dataIndex: 'submitterName',
				align: 'center',
				ellipsis: true
			},
			{
				title: this.l('Discoverer_Department'),
				width: 100,
				dataIndex: 'discovererDept',
				align: 'center',
				ellipsis: true,
			},
			 {title: this.l('audit.planstart'), width: 150, dataIndex: 'planStart', align: 'center',},
			{
				title: this.l('PlanCompleteTime'),
				width: 150,
				dataIndex: 'planEnd',
				align: 'center',
			},
			// {
			// 	title: this.l('audit.starttime'),
			// 	width: 150,
			// 	dataIndex: 'startTime',
			// 	align: 'center',
			// },
			{
				title: this.l('audit.closetime'),
				width: 150,
				dataIndex: 'closeTime',
				align: 'center',
			},
			// {
			// 	title: this.l('issueprogress.Progress'),
			// 	width: 80,
			// 	dataIndex: 'issueProgress',
			// 	align: 'center',
			// 	scopedSlots: {
			// 		customRender: "issueProgress"
			// 	}
			// },
			{
				title: this.l('220'),
				width: 200,
				dataIndex: 'auditPoint',
				align: 'center',
				ellipsis: true
			},
			{
				title: this.l('221'),
				width: 300,
				dataIndex: 'auditItemName',
				align: 'left',
				ellipsis: true
			},
			{
				title: this.l('AuditItemDescription'),
				width: 300,
				dataIndex: 'auditItemDescription',
				align: 'left',
				ellipsis: true
			},
			{
				title: this.l('audititem.audititemtypeid'),
				width: 100,
				dataIndex: 'auditItemTypeListName',
				align: 'left',
				ellipsis: true
			},
			{
				title: this.l('issue.correctiveNote'),
				width: 180,
				dataIndex: 'correctiveNote',
				align: 'left',
				ellipsis: true
			},
			{
				title: this.l('措施录入时间'),
				width: 180,
				dataIndex: 'correctiveNoteTime',
				align: 'left',
				ellipsis: true
			},
			{
				title: this.l('Whether_corrected_within_24H'),
				width: 150,
				dataIndex: 'twentyFourHour',
				align: 'left',
				ellipsis: true
			},
			{
				title: this.l('Whether_it_is_closed_within_30_days'),
				width: 150,
				dataIndex: 'thirtyDays',
				align: 'left',
				ellipsis: true
			},
			{
				title: this.l('Whether_it_recurs_within_six_months'),
				width: 150,
				dataIndex: 'recurrence',
				align: 'left',
				ellipsis: true
			},
			{
				title: this.l('姓名'),
				width: 150,
				dataIndex: 'submitterName',
				align: 'left',
				ellipsis: true
			},
			{
				title: this.l('工号'),
				width: 150,
				dataIndex: 'submitterJobID',
				align: 'left',
				ellipsis: true
			},
			{
				title: this.l('部门'),
				width: 150,
				dataIndex: 'submitterDept',
				align: 'left',
				ellipsis: true
			},
			{
				title: this.l('Actions'),
				width: 180,
				dataIndex: 'action',
				align: 'center',
				scopedSlots: {
					customRender: "action"
				},
				fixed: 'right'
			},
			],
			selectedRowKey: [], // 选择的数据Id
			selectedRow: [], //选择的整行数据
			selectedRowList: [],
			issueId: undefined,
			userId: undefined,
			UserId: undefined,
			isLoading: false, //加载中
			workProcessClass: [],
			AuditTaskStateList: undefined,
			treeRepClass: undefined,
			treeRepDataClass: undefined,
			status: undefined,
			show1: false,
			Progress: undefined,
			fileDownloadService: undefined,

			tree: [],
			treeRep: {
				title: 'name',
				key: 'id',
				value: 'id'
			},
			treeData: [],
			treeRepData: {
				title: 'name',
				key: 'id',
				value: 'id'
			},
			typeCodeList: [],
			sourceName: "即时问题",
			Task: {
				name: "未关闭",
				progress: -1,
			},
			List: "%",
			pic: undefined,
			photos: undefined,
			photo: [],
			image: "",
			uploadPictureUrl: AppConsts.OSSRequestUrl,
			isStar: false,

			//LPA看板传值
			timePeriod: [],
			startTime: undefined,
			endTime: undefined,
			orgtreeDataList: [],
			orgtreeReplaceFields: {
				title: 'levelNameAndName',
				key: 'id',
				value: 'id'
			},
			orgId: undefined,
			auditPointId: undefined,
			AllLPAUser: []
		}
	},
	methods: {
		orgChange(key) {
			console.log(key);
			this.orgId = key;
			this.getData();
		},

		getorgTreeData() {
			this.isLoading = true;
			this._LayoutOrgServiceProxy.getAllData(
			)
				.finally(() => {
					this.isLoading = false;
				})
				.then((res) => {
					this.orgtreeDataList = JSON.parse(JSON.stringify(res));
				})
		},
		//获取数据
		getData() {
			this.isLoading = true;
			this.IssueServiceProxy.getPaged(
				this.status,
				this.treeRepDataClass,
				this.treeRepClass,
				this.workProcessClass,
				this.startdate,
				this.enddate,
				this.isStar,
				this.Discoverystartdate,
				this.Discoveryenddate,
				this.orgId,
				this.UserId,
				"1",
				this.filterText ? this.filterText : undefined,
				undefined,
				this.request.maxResultCount,
				this.request.skipCount,
			).finally(() => {
				this.isLoading = false;
			}).then(res => {
				// console.log(res);
				this.tableData = res.items;

				this.tableData.forEach((item, index) => {
					// console.log(item.discoveryPicture);
					let a = [];
					(item.discoveryPicture || '').split(',').forEach(items => {
						if (!items == '') {
							a.push(items)
						}
					}
					);
					item.discoveryPicture = a;
					// console.log(item.discoveryPicture);
					// this.pic.map(item => {
					// 	// console.log(item);
					// 	this.photos = AppConsts.remoteServiceBaseUrl + item.realPath +'/'+ item;
					// 	this.photo.push(this.photos);
					// })
					// console.log(this.photo);
				});
				this.tableData.map(item => {
					item.discoveryTime = item.discoveryTime ? moment(item.discoveryTime).format(
						'YYYY-MM-DD HH:mm') : "";
					item.planEnd = item.planEnd ? moment(item.planEnd).format('YYYY-MM-DD') : "";
					item.planStart = item.planStart ? moment(item.planStart).format('YYYY-MM-DD') : "";
					item.closeTime = item.closeTime ? moment(item.closeTime).format(
						'YYYY-MM-DD HH:mm') : "";
					item.startTime = item.startTime ? moment(item.startTime).format(
						'YYYY-MM-DD') : "";
					item.correctiveNoteTime = item.correctiveNoteTime ? moment(item.correctiveNoteTime).format(
						'YYYY-MM-DD HH:mm') : "";
				})
				this.totalItems = res.totalCount;
				this.totalPages = Math.ceil(
					res.totalCount / this.request.maxResultCount
				);
				this.pagerange = [
					(this.pageNumber - 1) * this.request.maxResultCount + 1,
					this.pageNumber * this.request.maxResultCount,
				];
			})
		},
		show(item) {
			const vuer = this.$el.querySelector('.images').$vuer
			vuer.show()
		},
		//获取进度值
		getissueProgress() {
			this.isLoading = true;
			this.IssueProgressServiceProxy.getPaged().finally(() => {
				this.isLoading = false;
			}).then(res => {
				console.log(res);
				this.AuditTaskStateList = res.items;
				this.AuditTaskStateList.push(this.Task);
			})
		},
		//获取问题分类
		getTreeData2() {
			this.isLoading = true;
			this.IssueTypeServiceProxy.getPaged(

			).finally(() => {
				this.isLoading = false;
			}).then(res => {
				// console.log(res)
				this.tree = JSON.parse(JSON.stringify(res));
			})
		},
		//获取审核点
		getTreeData() {
			this.isLoading = true;
			this.AuditPointServiceProxy.getAllData(

			).finally(() => {
				this.isLoading = false;
			}).then(res => {
				this.treeData = JSON.parse(JSON.stringify(res));
			})
		},

		getAllLPAUser() {
			this.isLoading = true;
			this.AuditTaskServiceProxy.getAllLPAUsers(
			)
				.finally(() => {
					this.isLoading = false;
				})
				.then((res) => {
					this.AllLPAUser = res;
				})
		},

		LPAUserChange(value) {
			this.UserId = value;
			this.getData();
		},

		//关注
		concern(record) {
			// console.log(record.id)
			// this.isLoading = true;
			let issueId = { IssueId: record.id };
			if (record.issueSubscribeId) {
				this.IssueServiceProxy.removeStar(
					record.id
				).finally(() => {
					this.isLoading = false;
				}).then(res => {
					record.issueSubscribeId = '';
				})
			} else {
				this.IssueServiceProxy.addStar(
					issueId
				).finally(() => {
					this.isLoading = false;
				}).then(res => {
					record.issueSubscribeId = 'filled'
				})

			}
			// this.getData();
		},

		onChange(e) {
			console.log(e.target.checked);
			this.isStar = e.target.checked;
			this.getData();
		},

		showData() {
			if (this.show1 == false) {
				this.show1 = true;
			} else {
				this.show1 = false;
			}
		},
		handleMenuClick(rel) {
			console.log(rel.key);
			if (rel.key == 1) {
				this.Transfer();
			} else if (rel.key == 2) {
				this.deletetable();
			}
		},
		handleMenu(record) {
			console.log(record);
			this.selectedRowList.push(record.id);
			this.issueId = record.id;
			this.userId = record.respId;
		},
		//关闭问题
		CloseTheIssue(record) {
			ModalHelper.create(closeissue, {
				id: record.id
			}, {
				width: 480
			}).subscribe(res => {
				console.log(res)
				if (res) {
					this.getData();
					this.selectedRowKey = [];
					this.selectedRow = [];
				}
			})
		},
		//转办
		Transfer() {
			ModalHelper.create(transfer, {
				issueid: this.issueId,
				userid: this.userId,
				userType: 1
			}, {
				width: 1100
			}).subscribe(res => {
				console.log(res)
				if (res) {
					this.getData();
					this.selectedRowKey = [];
					this.selectedRow = [];
				}
			})
		},

		//编辑
		createissue() {
			ModalHelper.create(createissue, {
			}, {
				width: 1100
			}).subscribe(res => {
				// console.log(res)
				if (res) {
					this.getData();
					this.selectedRowKey = [];
					this.selectedRow = [];
				}
			})
		},

		//编辑
		edit(record) {
			ModalHelper.create(CreatErrorCode, {
				id: record.id,
				isKanban: false,
				iscreate: false,
			}, {
				width: 1100
			}).subscribe(res => {
				console.log(res)
				if (res) {
					this.getData();
					this.selectedRowKey = [];
					this.selectedRow = [];
				}
			})
		},
		//查看
		view(record) {
			ModalHelper.create(view, {
				id: record.id,
			}, {
				width: 1000
			}).subscribe(res => {
				if (res) {
					this.getData();
					this.selectedRowKey = [];
					this.selectedRow = [];
				}
			})
		},
		//批量删除事件
		batchdelete() {
			if (this.selectedRowKey[0] <= 0 || this.selectedRowKey[0] == null) {
				abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
				return;
			}
			this.$confirm({
				title: this.l("ConfirmInformation"),
				content: this.l("the_selected_option"),
				cancelText: this.l('Cancel'),
				okText: this.l('Ok'),
				onOk: () => {
					this.IssueServiceProxy.batchDelete(this.selectedRowKey).then(res => {
						this.getData();
						this.$notification["success"]({
							message: this.l("SuccessfullyDeleted"),
						});
					})
				}
			})
		},
		//删除
		deletetable() {
			this.$confirm({
				title: this.l("ConfirmInformation"),
				content: this.l("the_selected_option"),
				cancelText: this.l('Cancel'),
				okText: this.l('Ok'),
				onOk: () => {
					this.IssueServiceProxy.batchDelete(this.selectedRowList).then(res => {
						this.getData();
						this.$notification["success"]({
							message: this.l("SuccessfullyDeleted"),
						});
					})
				}
			})
		},
		//导出
		Export() {
			this.IssueServiceProxy.getToExcelIssue(
				this.status,
				this.treeRepDataClass,
				this.treeRepClass,
				this.workProcessClass,
				this.startdate,
				this.enddate,
				this.isStar,
				this.Discoverystartdate,
				this.Discoveryenddate,
				this.orgId,
				this.UserId,
				"1",
				this.filterText ? this.filterText : undefined,
				undefined,
				this.request.maxResultCount,
				this.request.skipCount,
				this.selectedRowKey
			)
				.then(res => {
					this._fileDownloadService.downloadTempFile(res);
				})
		},
		//行选择
		customRow: (record) => {
			return {
				on: {
					click: () => {
						_this.onSelect(record)
					}
				}
			}

		},
		//多选
		SelectAll(record, rows, crows) {
			if (record) {
				//更改表格数据勾选状态
				this.tableData.map(item => {
					item.checked = true;
				});
				rows.forEach((item, index) => {
					//判断是否存在
					let u = this.selectedRowKey.find(t => t == item.id);
					if (!u) {
						this.selectedRowKey.push(item.id);
						this.selectedRow.push(item);
					}
				});
			} else {
				//更改表格数据勾选状态
				this.tableData.forEach((item, index) => {
					item.checked = false;
					this.selectedRowKey = [];
					this.selectedRow = [];
				});
			}
		},
		// 重写复选框点击事件(不可删除)
		onSelect(record) {
			record.checked = !record.checked;
			if (record.checked) {
				this.selectedRow.push(record);
				this.selectedRowKey.push(record.id);
			} else {
				this.selectedRowKey = _.difference(_this.selectedRowKey, [record.id]);
				this.selectedRow = _.difference(_this.selectedRow, [record]);
			}
		},
		//分页
		onChangePage(page, pageSize) {
			this.pageNumber = page;
			this.request.skipCount = (page - 1) * this.request.maxResultCount;
			this.getData();
		},
		//正常分页展示形式，显示（共有 {0} 条）
		// showTotalFun() {
		// 	console.log(this.totalItems);
		//     return this.l(
		//         "GridFooterDisplayText",
		//         this.totalItems
		//     );
		// },
		showSizeChange(current, size) {
			this.pageNumber = 1;
			this.request.maxResultCount = size;
			this.request.skipCount = (this.pageNumber) * this.request.maxResultCount - size;
			this.getData();
		},
		//搜索框点击事件
		filteronchange() {
			this.pageNumber = 1;
			this.request.skipCount = 0;
			this.getData();
		},
		filterOption(input, option) {
			return (
				option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
			);
		},
		AuditTaskStateChange(value) {
			this.workProcessClass = value;
			this.getData();
		},
		treeRepChange(key) {
			console.log(key);
			this.treeRepClass = key;
			this.getData();
		},
		statusChange(value) {
			console.log(value); // { key: "lucy", label: "Lucy (101)" }
			this.status = value;
			this.getData();
			this.onChangePage(1, undefined);
		},
		timeChange(date, dateString) {
			this.startdate = date[0];
			this.enddate = date[1];
			this.getData();
		},

		DiscoverytimeChange(date, dateString) {
			this.Discoverystartdate = date[0];
			this.Discoveryenddate = date[1];
			this.getData();
		},

		treeRepChange1(key) {
			console.log(key);
			this.treeRepDataClass = key;
			this.getData();
		},
	}
}
</script>

<style>
.pagination {
	margin: 10px auto;
	text-align: right;
}

.portlet {
	display: flex;
	flex-direction: row;
}

.view-data {
	width: 100%;
	text-overflow: ellipsis;
	/* ellipsis:显示省略符号来代表被修剪的文本  string:使用给定的字符串来代表被修剪的文本*/
	white-space: nowrap;
	/* nowrap:规定段落中的文本不进行换行   */
	overflow: hidden;
	/*超出部分隐藏*/
}</style>
