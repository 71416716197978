import { RouteConfig } from 'vue-router';
import { LayoutBlock } from '@/layout';

const ReportRouting: RouteConfig[] = [
    {
        path: 'report',
        meta: { title: '报表' },
        component: LayoutBlock,
        redirect: '/app/report/Report-inventory',
        children: [
            {
                name: 'Report-inventory',
                path: 'Report-inventory',
                component:()=>import('./Report-inventory/Report-inventory.vue'),
                meta: { title: '报表' },
            },
			{
			    name: 'Incomplete-auditors',
			    path: 'Incomplete-auditors',
			    component:()=>import('./Incomplete-auditors/Incomplete-auditors.vue'),
			    meta: { title: '审核员未审核数量' },
			},
			{
			    name: 'Departmental-completion',
			    path: 'Departmental-completion',
			    component:()=>import('./Departmental-completion/Departmental-completion.vue'),
			    meta: { title: '部门审核完成率' },
			},
			{
			    name: 'Results-match',
			    path: 'Results-match',
			    component:()=>import('./Results-match/Results-match.vue'),
			    meta: { title: '审核结果符合率' },
			},
			{
			    name: 'Nonconformances',
			    path: 'Nonconformances',
			    component:()=>import('./Nonconformances/Nonconformances.vue'),
			    meta: { title: '不符合项的数量' },
			},
			{
			    name: 'Departmental-issues',
			    path: 'Departmental-issues',
			    component:()=>import('./Departmental-issues/Departmental-issues.vue'),
			    meta: { title: '部门问题关闭率' },
			},
			{
			    name: 'Hours-issue',
			    path: 'Hours-issue',
			    component:()=>import('./Hours-issue/Hours-issue.vue'),
			    meta: { title: '24小时内问题关闭率' },
			},
			{
			    name: 'NoClose-issue',
			    path: 'NoClose-issue',
			    component:()=>import('./NoClose-issue/NoClose-issue.vue'),
			    meta: { title: '30天未关闭问题清单' },
			},
			{
			    name: 'Departmental-NoClose-issue',
			    path: 'Departmental-NoClose-issue',
			    component:()=>import('./Departmental-NoClose-issue/Departmental-NoClose-issue.vue'),
			    meta: { title: '30天未关闭问题-部门' },
			},
			{
			    name: 'Responsible-NoClose-issue',
			    path: 'Responsible-NoClose-issue',
			    component:()=>import('./Responsible-NoClose-issue/Responsible-NoClose-issue.vue'),
			    meta: { title: '30天未关闭问题-负责人' },
			},
			{
			    name: 'Duplicate-issues',
			    path: 'Duplicate-issues',
			    component:()=>import('./Duplicate-issues/Duplicate-issues.vue'),
			    meta: { title: '重复问题关闭率' },
			},
			{
			    name: 'Audit-item-type',
			    path: 'Audit-item-type',
			    component:()=>import('./Audit-item-type/Audit-item-type.vue'),
			    meta: { title: '审核项类型符合率' },
			},
			{
			    name: 'Issue-type',
			    path: 'Issue-type',
			    component:()=>import('./Issue-type/Issue-type.vue'),
			    meta: { title: '问题分类关闭率' },
			},
			{
			    name: 'Level-Monthly-Miew',
			    path: 'Level-Monthly-Miew',
			    component:()=>import('./Level-Monthly-Miew/Level-Monthly-Miew.vue'),
			    meta: { title: '层级月视图' },
			},
			{
			    name: 'Statistics-on-sectoral-issues',
			    path: 'Statistics-on-sectoral-issues',
			    component:()=>import('./Statistics-on-sectoral-issues/Statistics-on-sectoral-issues.vue'),
			    meta: { title: '部门问题统计' },
			},
			{
			    name: 'Departmental-hidden',
			    path: 'Departmental-hidden',
			    component:()=>import('./Departmental-hidden/Departmental-hidden.vue'),
			    meta: { title: '部门隐患月度发现数量' },
			},
			{
			    name: 'Key-position-personnel',
			    path: 'Key-position-personnel',
			    component:()=>import('./Key-position-personnel/Key-position-personnel.vue'),
			    meta: { title: '关键岗位人员月度隐患排查数量' },
			},
			{
			    name: 'Departmental-hidden-close',
			    path: 'Departmental-hidden-close',
			    component:()=>import('./Departmental-hidden-close/Departmental-hidden-close.vue'),
			    meta: { title: '隐患问题部门关闭率' },
			},
			{
			    name: 'Departmental-hidden-close-year',
			    path: 'Departmental-hidden-close-year',
			    component:()=>import('./Departmental-hidden-close-year/Departmental-hidden-close-year.vue'),
			    meta: { title: '隐患问题部门年度关闭率' },
			},
			{
			    name: 'Personnel-problem-statistics',
			    path: 'Personnel-problem-statistics',
			    component:()=>import('./Personnel-problem-statistics/Personnel-problem-statistics.vue'),
			    meta: { title: '人员问题统计表' },
			},
        ],
    },
];
export default ReportRouting;
